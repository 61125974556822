import printJS from 'print-js'
import { sizeof } from 'sizeof'
import type { CaseConfig } from '~/types/CaseConfig'
import type { Order } from '~/types/Order'
import { PdfCaseData, PdfData } from '~/types/PdfData'

// Imported from head script defined in nuxt.config.ts (Local Js lib)

declare function html2canvas (el: any, options: any): any

export default function usePdf () {
  const { showAlert } = useAlert()

  const [screenshoting, toggleScreenshoting] = useToggle(false)

  const generatingPdf = ref(false)
  const printingPdf = ref(false)
  const generatingOrderPdf = ref(false)

  function optimizeCanvasSize (canvas: HTMLCanvasElement) {
    if (!canvas)
      return canvas
    console.log(canvas.width, canvas.height)
    const width = canvas?.width
    const height = canvas?.height

    // Create a temporary canvas to resize
    const tempCanvas = document.createElement('canvas')
    const tempContext = tempCanvas.getContext('2d')

    // Set the dimensions of the temporary canvas
    tempCanvas.width = width / 2 // Adjust the resizing factor as needed
    tempCanvas.height = height / 2

    // Draw the original canvas onto the temporary canvas
    if (!tempContext)
      return canvas
    tempContext.drawImage(canvas, 0, 0, tempCanvas.width, tempCanvas.height)

    return tempCanvas
  }

  async function screenshotCases (caseConfig: CaseConfig) {
    toggleScreenshoting(true)
    const canvases = await Promise.all(
      ['screenshot_frontView', 'screenshot_insideView', 'screenshot_outsideView', 'screenshot_outsideView2']
        .map<Promise<HTMLCanvasElement>>(async id => {
          const el = document.getElementById(id)
          if (!el)
            return undefined
          return (await html2canvas(el, {
            allowTaint: true,
            useCORS: true,
            backgroundColor: 'white',
          }))
        }),
    )
    let screenshots64 = canvases.map(canvas => {
      console.log(`canvas w x h: ${canvas?.width} x ${canvas?.height}`)
      return canvas?.toDataURL().split(',').reverse()[0]
    })
    if (sizeof(screenshots64) > 800000) {
      screenshots64 = canvases.map(canvas => {
        console.log(`optimized canvas w x h: ${canvas?.width}x ${canvas?.height}`)
        console.log('id:', caseConfig.id)
        return optimizeCanvasSize(canvas)?.toDataURL().split(',').reverse()[0]
      })
    }

    console.log(`size of case config document:  ${sizeof(caseConfig)}b`)

    const caseType = caseConfig.isSr ? 'SR' : caseConfig.isSp ? 'SP' : 'SZ'
    caseConfig.pdfData = new PdfData(
      {
        front: screenshots64[0]!,
        inside: screenshots64[1]!,
        outside: screenshots64[2],
        outside2: screenshots64[3],
      },
      new PdfCaseData(
        caseType,
        caseConfig.pdfEi,
        caseConfig.caseLabel || '',
        caseConfig.notesCompany || '',
        caseConfig.notesLabel || '',
        caseConfig.notesNotes || '',
        caseConfig.caseInfo?.orderNumber?.toString() || '',
      ),
      caseConfig.getSummaryItems(),
    )

    try {
      await $fetch(`/api/case-config/${caseConfig.id}`, {
        method: 'POST',
        body: caseConfig.toJson(),
      })
    }
    catch (err) { console.error(err) }

    toggleScreenshoting(false)
  }

  async function generatePdf (caseConfig: CaseConfig) {
    if (!caseConfig.pdfData)
      await screenshotCases(caseConfig)

    if (screenshoting.value)
      await until(screenshoting).toBe(false)

    const response = await $fetch<Blob>(`/api/pdf/caseconfig-pdf/${caseConfig.id}`)
    return response
  }

  async function getPdf (caseConfig: CaseConfig, options?: { print?: boolean }) {
    if (options?.print)
      printingPdf.value = true
    else
      generatingPdf.value = true
    try {
      const response = await generatePdf(caseConfig)
      if (options?.print) {
        printJS(
          {
            printable: URL.createObjectURL(response),
            type: 'pdf',
          })
      }
      else {
        const url = URL.createObjectURL(response!)

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${caseConfig.caseLabel}.pdf`)

        document.body.appendChild(link)

        link.click()

        URL.revokeObjectURL(url)
        document.body.removeChild(link)
      }
    }
    catch (e) {
      showAlert({
        intent: 'danger',
        title: 'Selhání stažení PDF, prosím zkuste aktualizovat stránku',
      })
      console.error(e)
    }
    printingPdf.value = false
    generatingPdf.value = false
  }
  async function getOrderPdf (order: Order) {
    generatingOrderPdf.value = true

    try {
      const response = await $fetch<Blob>(`/api/pdf/order-pdf`, {
        method: 'POST',
        body: order.toJson(),
      })

      const url = URL.createObjectURL(response!)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${order.code}.pdf`)

      document.body.appendChild(link)

      link.click()

      URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }

    catch (e) {
      showAlert({
        intent: 'danger',
        title: 'Selhání stažení PDF, prosím zkuste aktualizovat stránku',
      })
      console.error(e)
    }

    generatingOrderPdf.value = false
  }

  return {
    screenshoting,
    toggleScreenshoting,
    generatePdf,
    getPdf,
    getOrderPdf,
    screenshotCases,
    generatingPdf,
    printingPdf,
    generatingOrderPdf,
  }
}
