import type { SummaryItem } from './SummaryItem'

export class PdfData {
  constructor (
    public images: {
      front: string
      inside: string
      outside?: string
      outside2?: string
    },
    public caseData: PdfCaseData,
    public summaryItems: SummaryItem[],
  ) { }

  static fromJson (json: Record<string, any>): PdfData {
    return new PdfData(
      json.images,
      PdfCaseData.fromJson(json.caseData),
      json.summaryItems,
    )
  }

  public toJson (): Record<string, any> {
    return {
      images: this.images,
      caseData: this.caseData.toJson(),
      summaryItems: this.summaryItems,
    }
  }
}

export class PdfCaseData {
  constructor (
    public type: 'SZ' | 'SP' | 'SR',
    public ei: 'EI30' | 'EI45' | 'EI60' | 'EI90',
    public title: string,
    public company?: string,
    public event?: string,
    public note?: string,
    public orderNumber?: string,
  ) { }

  static fromJson (json: Record<string, any>): PdfCaseData {
    return new PdfCaseData(
      json.type,
      json.ei,
      json.title,
      json.company,
      json.event,
      json.note,
    )
  }

  public toJson (): Record<string, any> {
    return {
      type: this.type,
      ei: this.ei,
      title: this.title,
      company: this.company,
      event: this.event,
      note: this.note,
    }
  }
}
